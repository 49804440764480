import { useEffect } from "react";

const AreWeThereYet = () => {
  useEffect(() => {
    window.location.replace("https://forms.gle/6pnS5rigCfcb411DA");
  }, []);

  return null;
};

export default AreWeThereYet;
