import { useEffect } from "react";

const Picnic = () => {
  useEffect(() => {
    window.location.replace("https://forms.office.com/r/dAUc4RDJQg");
  }, []);

  return null;
};

export default Picnic;
